<script>
export default {
    name: "loader"
}
</script>

<template>
    <div id="preloading-spinner-wrap" class="loading-spinner-wrap">
        <div class="spinner">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_13102_179492" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="16" y="4" width="7" height="8">
                    <path d="M16.2674 6.44975L18.0986 4.07349C20.4813 5.90966 22.003 8.7953 22.0003 12H19.0002C19.0024 9.75877 17.941 7.73945 16.2674 6.44975Z" fill="url(#paint0_linear_13102_179492)"/>
                </mask>
                <g mask="url(#mask0_13102_179492)">
                    <rect x="1" y="1" width="22" height="22" fill="#007CFF"/>
                </g>
                <mask id="mask1_13102_179492" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="12" y="12" width="11" height="10">
                    <path d="M12 18.9998C15.7006 19.0029 18.7946 16.104 18.9905 12.366C18.9969 12.2434 19.0001 12.1214 19.0002 12H22.0003C22.0002 12.1734 21.9955 12.3478 21.9864 12.523C21.7065 17.863 17.2866 22.0043 12 21.9999V18.9998Z" fill="url(#paint1_linear_13102_179492)"/>
                </mask>
                <g mask="url(#mask1_13102_179492)">
                    <rect x="1" y="1" width="22" height="22" fill="#007CFF"/>
                </g>
                <mask id="mask2_13102_179492" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="1" y="12" width="11" height="10">
                    <path d="M11.6337 18.99C11.7564 18.9964 11.8785 18.9997 12 18.9998V21.9999C11.8265 21.9997 11.652 21.9951 11.4767 21.9859C6.13687 21.7061 1.99556 17.2864 1.99982 12H4.99989C4.99697 15.7004 7.89587 18.7941 11.6337 18.99Z" fill="url(#paint2_linear_13102_179492)"/>
                </mask>
                <g mask="url(#mask2_13102_179492)">
                    <rect x="1" y="1" width="22" height="22" fill="#007CFF"/>
                </g>
                <mask id="mask3_13102_179492" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="1" y="1" width="13" height="11">
                    <path d="M12.3664 5.00911C8.50572 4.80678 5.21199 7.77247 5.00965 11.6332C5.00322 11.756 4.99999 11.8783 4.99989 11.9999H1.99982C1.99996 11.8262 2.00457 11.6516 2.01377 11.4762C2.30281 5.96088 7.00814 1.72418 12.5234 2.01322C13.3507 2.05658 13.9862 2.76238 13.9429 3.58967C13.8995 4.41696 13.1937 5.05247 12.3664 5.00911Z" fill="url(#paint3_linear_13102_179492)"/>
                </mask>
                <g mask="url(#mask3_13102_179492)">
                    <rect x="1" y="1" width="22" height="22" fill="#007CFF"/>
                </g>
                <defs>
                    <linearGradient id="paint0_linear_13102_179492" x1="19" y1="12" x2="16" y2="7" gradientUnits="userSpaceOnUse">
                        <stop stop-opacity="0.08"/>
                        <stop offset="1" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_13102_179492" x1="12" y1="19" x2="19" y2="12" gradientUnits="userSpaceOnUse">
                        <stop stop-opacity="0.35"/>
                        <stop offset="1" stop-opacity="0.08"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_13102_179492" x1="5" y1="12" x2="12" y2="19" gradientUnits="userSpaceOnUse">
                        <stop stop-opacity="0.65"/>
                        <stop offset="1" stop-opacity="0.35"/>
                    </linearGradient>
                    <linearGradient id="paint3_linear_13102_179492" x1="14" y1="4.99991" x2="5" y2="11.9999" gradientUnits="userSpaceOnUse">
                        <stop/>
                        <stop offset="1" stop-opacity="0.65"/>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    </div>
</template>

<style lang="scss">
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loading-spinner-wrap {
    position: fixed;
    background: #F2F3F7;
    display: none;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    margin: 0;
    top: 0;
    left: 0;
}

.spinner {
    display: block;
    width: 26px;
    height: 26px;
    margin: auto;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
</style>